import { graphql } from "gatsby";
import * as DubaiTourData from "../../../pageData/DubaiTourData/DubaiTourData.json"

const query = graphql`
  query DubaiTourQuery {
    allPrismicDubaiTour {
      nodes {
        data {
          dubai_top_heading {
            text
          }
          dubai_top_description{
            text
          }
          dubai_right_image{
            url
          }
          dubai_top_heading1 {
            text
          }
          dubai_top_description1{
            text
          }
          dubai_left_image{
            url
          }
          dubai_top_description2{
            text
          }
          dubai_heading {
            text
          }
          terms_box{
            DemoTerms_content {
              url
            }
          }
          dubaiimages_box { 
            dubaiimages_icon {
              url
            }
          }
          four_box {            
            four_icon {
              url
            }
            four_heading {
              text
            }
            four_content {
              text
            }
          },
          three_box { 
            three_heading {
              text
            }
            three_content {
              text
            }
          },
          key_figures_boxes {
            key_feature_box_description {
              text
            }
            key_feature_box_title {
              text
            }
            number_only {
              text
            }
            number_prefix {
              text
            }
            number_sufix {
              text
            }
          }
        }
        lang
      }
    }
  }
`;

const dataResolver = (response, lang) => {
  const { allPrismicDubaiTour } = response;
  return allPrismicDubaiTour.nodes.find((node) => node.lang === lang).data;
};

const useData = (lang) => {
  // debugger;
  // const response = useStaticQuery(query);
  // return dataResolver(response, lang);
  return dataResolver(DubaiTourData, lang);
};

export default useData;
