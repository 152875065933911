import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
const DubaiTourCountDown = ({ targetDate }) => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="countdown-main">
      <div className="countdown-time"><span className="countdown-content"> 
      {/* {timeRemaining.days} */}0
      </span><span className="countdown-head">Days</span></div>
      <span className="countdown-col">:</span>
      <div className="countdown-time"><span className="countdown-content"> 
      {/* {timeRemaining.hours} */}0
      </span><span className="countdown-head">Hours</span></div>
      <span className="countdown-col">:</span>
      <div className="countdown-time"><span className="countdown-content"> 
      {/* {timeRemaining.minutes} */}0
      </span><span className="countdown-head">Minutes</span></div>
      <span className="countdown-col">:</span>
      <div className="countdown-time"><span className="countdown-content"> 
      {/* {timeRemaining.seconds} */}0
      </span><span className="countdown-head">Seconds</span></div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(DubaiTourCountDown);